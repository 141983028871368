.navbar {
  #menu-header {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    color: #333333;
    font-size: 14px;

    :hover {
      color: #e30613;
    }

    .active {
      color: #e30613;
    }
  }
  .megamenu {
    padding: 1rem;
  }
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .navbar {
    .has-megamenu {
      position: static !important;
    }
    .megamenu {
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 95%;
      margin-top: 0;
      text-align: center;
    }
  }
}

/* ============ mobile view ============ */
@media(max-width: 991px) {
  .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse {
    overflow-y: auto;
    max-height: 90vh;
    margin-top: 10px;
  }
}